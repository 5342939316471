import React from 'react'
import './about.css'
import ME from '../../assets/pic-2.png'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'


const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know Me</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="2png img" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>Junior Software Engineer</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Project</h5>
              <small>10+ Completed</small>
            </article>
          </div>
          <p>
          Experienced computer science student and junior software engineer with a strong background in Python automation programming and machine learning research. Proficient in Python, C++, Java, SQL, and Linux environment. Seeking opportunities to further develop technical skills and contribute to innovative projects.
          </p>
          <p>Photography is another hobby that brings me great pleasure. One of my favorite shots, taken on a beautiful day, captures the reflection of that moment on my car's window.</p>
          <div className="about__me_middle">
              <a href="#contact" className='btn btn-primary'>Contact Me</a>
              <a href="https://www.linkedin.com/in/gia-minh-hoang/" target="_blank" className='btn'>Let's Network</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About