import React from 'react'
import './portfolio.css'
import img1 from '../../assets/1.png'
import img2 from '../../assets/2.png'
import img3 from '../../assets/3.png'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">


         {/* Card 1 */}
      <article className='portfolio__item'>
        <a href={img1} target='_blank'>
            <div className="portfolio__item-image">
              <img src={img1} alt="" />
            </div>
            <div className="portfolio__image">
                <h3><br></br>ULO Shipping: autonomously generates shipping labels, the label's weight will be (auto) adjusted to match the customer's order specifications</h3>
            </div>
          </a>
          {/* <div className="portfolio__item-cta">
            <a href="https://github.com/cellanet/Auto_Shipping_Bot" className='btn btn-primary' target='_blank'>GitHub</a>
          </div> */}
        </article>


         {/* Card 2 */}
        <article className='portfolio__item'>
          <a href={img2} target='_blank'>
            <div className="portfolio__item-image">
              <img src={img2} alt="" />
            </div>
            <div className="portfolio__image">
              <h3><br></br>ULO Shipper: seamlessly integrates with third-party APIs such as UPS, USPS, FedEx, and QuickBooks, tailored to match the company's workflow.</h3>
            </div>
          </a>
        </article>

         {/* Card 3 */}
        <article className='portfolio__item'>
          <a href={img3} target='_blank'>
            <div className="portfolio__item-image">
              <img src={img3} alt="" />
            </div>
            <div className="portfolio__image">
              <h3>Project Raise: Undergraduate Research, Summer 2022. Developed a machine learning chatbot to stimulate conversations. Return in 2023 as a mentor to help other students.</h3>
            </div>
          </a>
        </article>
      </div>
    </section>
  )
}

export default Portfolio