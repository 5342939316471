import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'    
import Exp from './components/Exp/Exp'
import Projects from './components/projects/projects'
import Portfolio from './components/portfolio/Portfolio'
import Contact from './components/Contact/Contact'

const App = () => {
return (
    <>
        <Header/>
        <Nav/>
        <About/>
        <Exp/>
        <Projects/>
        <Portfolio/>
        <Contact/>
    </>
)
}

export default App