import React from "react";
import "./projects.css";
import { BiCheck } from "react-icons/bi";
const Projects = () => {
  return (
    <section id="projects">
      <h5> My Work</h5>
      <h2>Projects</h2>

      <div className="container projects__container">

         {/* Proj 1 */}
        <article className="project">
          <div className="project__head">
            <h3>OpenERP / Odoo</h3>
          </div>
          <ul className="project__list">
            <li>
              <BiCheck className="project__list-icon" />
              <p>Maintained and fixed bugs for company's OpenERP system hosted on AWS EC2 instance within a virtual Linux environment.</p>
            </li>
            <li>
              <BiCheck className="project__list-icon" />
              <p>Utilized skills in Python programming and PostgresSQL database management to troubleshoot issues and ensure optimal system performance.</p>
            </li>
            <li>
              <BiCheck className="project__list-icon" />
              <p>Demonstrated proficiency in cloud computing and Linux system administration through maintaining company servers within a virtual Linux machine hosted on AWS.</p>
            </li>
          </ul>
        </article>

          {/* Proj 2 */}
        <article className="project">
          <div className="project__head">
            <h3>Python Automation</h3>
          </div>
          <ul className="project__list">
            <li>
              <BiCheck className="project__list-icon" />
              <p>Developed a bot to expedite the workflow of finding issues with items returned from customers in the service department.</p>
            </li>
            <li>
              <BiCheck className="project__list-icon" />
              <p>Creates an automated bot that integrates with UPS and Shippo APIs to generate shipping labels from multiple providers, sends shipping notification emails to customers, and forwards tickets to the shipping department for packing items to be sent out to customers.</p>
            </li>
            <li>
              <BiCheck className="project__list-icon" />
              <p>Built a custom Shipping App using UPS, Shippo, and QuickBooks APIs to streamline shipping services into one app, tailored to improve employee workflow efficiency.</p>
            </li>
          </ul>
        </article>

          {/* Proj 3 */}
        <article className="project">
          <div className="project__head">
            <h3>Machine Learning Research</h3>
          </div>
          <ul className="project__list">
            <li>
              <BiCheck className="project__list-icon" />
              <p>Participated in the Undergraduate Summer Research (URE) program at Cal State Fulleron, worked under the guidance of Dr. Doina Bein. in 2022.</p>
            </li>
            <li>
              <BiCheck className="project__list-icon" />
              <p>Learned about various machine learning models, including classification and deep neural networks, along with TensorFlow and Natural Language Processing, and applied this knowledge to create an advanced conversational chatbot.</p>
            </li>
            <li>
              <BiCheck className="project__list-icon" />
              <p>Presented the results of the project as a poster at the URE 2022 conference on August 11, 2022. Return as an Undergraduate Research Assistant/Mentor to help other students conduct their research on Jun 2023 - Sept 2023.</p>
            </li>
          </ul>
        </article>

      </div>
    </section>
  );
};

export default Projects;
